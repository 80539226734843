import React, { FC, useRef } from 'react'
import { Text } from 'components/dataDisplay'
import { WidthContainer } from 'components/layout'
import { Button } from 'components/inputs'
import s from './LatestReleases.module.scss'
import ListingCard from 'components/dataDisplay/ListingCard/ListingCard'
import { useDevice } from 'device'
import { useDraggable } from 'react-use-draggable-scroll'


const LatestReleases: FC<{data: BackendListing[]}> = ({ data }) => {
  const { isMobile } = useDevice()

  const ref = useRef(null)
  const { events } = useDraggable(ref)

  if (!data?.length) {
    return <div ref={ref} />
  }

  return (
    <WidthContainer className={isMobile ? 'mt-92' : 'mt-120'}>
      <div className="flex justify-between">
        <Text style="h3" tag="h2">Latest Releases</Text>
        {
          !isMobile && (
            <Button
              size={40}
              title="See all"
              style="stroked"
              to="/curated-gallery"
              className={s.seeAll}
            />
          )
        }
      </div>
      <div
        className={s.listings}
        {...events}
        ref={ref}
      >
        {
          data?.map(listing => (
            <ListingCard
              key={listing.id}
              listing={listing}
            />
          ))
        }
      </div>
      {
        isMobile && (
          <Button
            className="mt-24"
            size={40}
            title="See all"
            style="stroked"
            to="/curated-gallery"
            fullWidth
          />
        )
      }
    </WidthContainer>
  )
}

export default LatestReleases
