import React, { FC } from 'react'
import { Avatar, Text } from 'components/dataDisplay'
import { WidthContainer } from 'components/layout'
import s from './PastReleases.module.scss'
import { Button } from 'components/inputs'
import { useFetchUser } from 'hooks'
import Link from 'next/link'
import { useDevice } from 'device'
import { Animated } from 'components/feedback'


const Card: FC<{ edition: { id: number, user: string, name: string, img: string } }> = ({ edition }) => {
  const { data } = useFetchUser({ address: edition?.user })
  const { isMobile } = useDevice()

  if (!edition) {
    return null
  }

  const { id, name, img } = edition || {}

  return (
    <div className={s.edition} key={name}>
      <Link href={`/listing/${id}`}>
        <a title={name}>
          <img loading="lazy" src={img} alt={name} />
        </a>
      </Link>
      <div className={s.overlay}>
        <Text style={isMobile ? 'c3' : 'p2'} color="white-100" tag="p">
          {name}
        </Text>
        {
          data && (
            <Link href={`/user/${data?.username}`}>
              <a className="mt-4 flex items-center hover:underline" title={data?.display_name || data?.username}>
                <Avatar size={isMobile ? 14 : 28} address={data?.address} src={data?.avatar} />
                <Text
                  className="ml-8 nowrap"
                  style={isMobile ? 'c3' : 'p2'}
                  message={`by ${data?.display_name || data?.username}`}
                  color="white-100"
                  tag="p"
                />
              </a>
            </Link>
          )
        }
      </div>
    </div>
  )
}

const PastReleases: FC<{ withButon?: boolean, data: {id: number, user: string, name: string, img: string}[] }> = ({ withButon = true, data }) => {
  const { isMobile } = useDevice()

  if (!data?.length) {
    return null
  }

  if (isMobile) {
    return (
      <WidthContainer className="mt-40">
        <div className="flex justify-between">
          <Text style="h3" tag="h2">Past Releases</Text>
        </div>

        <Animated className={'mt-24 flex gap-12'} type="fadeInUp">
          <div className="flex flex-col flex-1 gap-12">
            <Card edition={data?.[0]} />
            <Card edition={data?.[1]} />
            <Card edition={data?.[2]} />
            <Card edition={data?.[3]} />
          </div>
          <div className="flex flex-col flex-1 gap-12">
            <Card edition={data?.[4]} />
            <Card edition={data?.[5]} />
            <Card edition={data?.[6]} />
            <Card edition={data?.[7]} />
          </div>
        </Animated>

        {
          withButon && (
            <Button
              className="mt-24"
              size={40}
              title="See all"
              style="stroked"
              to="/curated-gallery/ended"
              fullWidth
            />
          )
        }
      </WidthContainer>
    )
  }

  return (
    <WidthContainer className="mt-80">
      <div className="flex justify-between">
        <Text style="h3" tag="h2" message="Past Releases" />
        {
          withButon && (
            <Button
              size={40}
              title="See all"
              style="stroked"
              to="/curated-gallery/ended"
              className={s.seeAll}
            />
          )
        }
      </div>
      <Animated type="fadeInUp" className={'mt-40 mb-100 flex gap-24'}>
        <div className="flex flex-col flex-1 gap-24">
          <Card edition={data?.[0]} />
          <Card edition={data?.[1]} />
        </div>
        <div className="flex flex-col flex-1 gap-24">
          <Card edition={data?.[2]} />
          <Card edition={data?.[3]} />
        </div>
        <div className="flex flex-col flex-1 gap-24">
          <Card edition={data?.[4]} />
          <Card edition={data?.[5]} />
        </div>
        <div className="flex flex-col flex-1 gap-24">
          <Card edition={data?.[6]} />
          <Card edition={data?.[7]} />
        </div>
      </Animated>
    </WidthContainer>
  )
}

export default PastReleases
