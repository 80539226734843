import { restAPI } from 'helpers'
import safePromise from 'helpers/safePromise'


type ListingQueryResult = {
  data: {
    listings: BackendListing[]
    total_count: number
  }
  error: boolean
  error_message: string
}

const getInitialData = async () => {
  const listingsUrl = '/listings'
  const latestQuery = '?curated=true&status=ACTIVE&status=RESOLVING&status=SUCCESSFUL&status=FINALIZED&sort=featured'
  const pastQuery = '?status=FINALIZED&status=SUCCESSFUL'

  let latestListings = [], pastListings = []

  try {
    const [ latestListingsResponse, pastListingsResponse ] = await safePromise([
      restAPI.get<ListingQueryResult>(listingsUrl + latestQuery),
      restAPI.get<ListingQueryResult>(listingsUrl + pastQuery),
    ])

    latestListings = [ ...latestListingsResponse.data.data.listings.slice(0, 20) ]

    const editions = pastListingsResponse.data.data.listings.map(listing => {
      return { id: listing.id, user: listing.creator, name: listing.edition_name, img: listing.edition_cache }
    })
    const shuffled = editions.sort(() => Math.random() - 0.5)
    pastListings = shuffled.slice(0, 8)
  }
  catch (e) {
    console.log(e)
    console.error('error in HomePage getInitialData getListings')
  }

  return { latestListings, pastListings }
}
export default getInitialData
