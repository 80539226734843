import React from 'react'
import { InferGetStaticPropsType, GetStaticProps } from 'next'
import s from './HomePage.module.scss'
import Artists from './components/Artists/Artists'
import Hero from './components/Hero/Hero'
import LatestReleases from './components/LatestReleases/LatestReleases'
import BabylonBlock from './components/BabylonBlock/BabylonBlock'
import PastReleases from './components/PastReleases/PastReleases'
import safePromise from 'helpers/safePromise'
import getInitialData from './utils/getInitialData'


type HomePageProps = {
  latestListings: BackendListing[],
  pastListings: {id: number, user: string, name: string, img: string}[]
}

const HomePage = ({ latestListings, pastListings } : InferGetStaticPropsType<typeof getStaticProps>) => {
  return (
    <div className={s.landing}>
      <Hero />
      <LatestReleases data={latestListings} />
      <PastReleases data={pastListings} />
      <Artists />
      <BabylonBlock />
    </div>
  )
}

export const getStaticProps: GetStaticProps<HomePageProps> = async () => {
  const { latestListings, pastListings } = await getInitialData()

  return { props: { latestListings, pastListings }, revalidate: 10 }
}

export default HomePage
