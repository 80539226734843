import React, { FC } from 'react'
import cx from 'classnames'
import { Text } from 'components/dataDisplay'
import { Button } from 'components/inputs'
import s from './BabylonBlock.module.scss'
import { useDevice } from 'device'
import { event } from 'helpers'
import { Animated } from 'components/feedback'
import { WidthContainer } from 'components/layout'


const BabylonBlock: FC = () => {
  const { isMobile, isIOS } = useDevice()

  return (
    <WidthContainer className={s.container}>
      <div className="flex flex-col items-center">
        <div className={cx(s.babylon, isIOS && s.ios)}>
          <div className={s.info}>
            <Text style="h2" color="inherit" tag="h2">WHAT WE ARE</Text>
            <Text
              className={isMobile ? 'mt-8' : 'mt-16'}
              style="sub"
              tag="p"
              message="A commercial art gallery specializing in NFT editions, exclusively run by creators and collectors for creators and collectors with inclusivity in mind"
            />
          </div>
        </div>
        <div className={cx('w-full flex justify-between', isMobile ? 'flex-col my-40' : 'my-64')}>
          <div className="flex-1">
            <Animated type="fadeInLeft" className={s.w580}>
              <Text style="h3" tag="h3" color="gray-90">For NFT Creators</Text>
              <Text className="mt-12" style="p1" tag="p" color="gray-50">
                a platform to easily display and sell their work, boosting their portfolios for an opportunity to be featured in our Curated gallery. At market-beating primary fees and with no strings attached.
              </Text>
              <Button
                className="mt-24"
                style="secondary"
                title="list editions"
                to="/create"
                onClick={() => event({ event:'list_editions', version: 'about' })}
                size={64}
                fullWidth={isMobile}
              />
            </Animated>
          </div>
          <div className={cx('flex-1', isMobile && 'mt-24')}>
            <Animated className={s.w580} type="fadeInRight">
              <Text style="h3" tag="h3" color="gray-90">For NFT Collectors</Text>
              <Text className="mt-12" style="p1" tag="p" color="gray-50">
                a space to grow their collections with accessible artworks by established and up-and-coming artists, where each purchased edition comes with a chance to win a unique piece.
              </Text>
              <Button
                className="mt-24"
                style="secondary"
                title="collect editions"
                onClick={() => event({ event: 'collect_editions', version: 'about' })}
                to="/curated-gallery"
                fullWidth={isMobile}
                size={64}
              />
            </Animated>
          </div>
        </div>
        <div className={cx(s.babylon, isIOS && s.ios)}>
          <div className={cx(s.info, s.w820)}>
            <Text style="h3" color="white-100" tag="p">All in one.  All  inclusive.</Text>
            <Text className="mt-12 uppercase" style="h2" color="white-100" tag="p">Home of NFT Editions</Text>
            <div className={s.customButtons}>
              <Button
                title="explore editions"
                size={64}
                style="secondary"
                to="/curated-gallery"
                onClick={() => event({ event: 'explore_editions' })}
                fullWidth={isMobile}
              />
              <Button
                title="create listing"
                size={64}
                onClick={() => event({ event: 'create_listing' })}
                style="stroked"
                to="/create"
                fullWidth={isMobile}
              />
            </div>
          </div>
        </div>
      </div>
    </WidthContainer>
  )
}

export default BabylonBlock
