import React, { FC, useRef } from 'react'
import { Text } from 'components/dataDisplay'
import s from './Hero.module.scss'
import { Button } from 'components/inputs'
import cx from 'classnames'
import { WidthContainer } from 'components/layout'
import { useDevice } from 'device'
import { event } from 'helpers'
import { UseThrottlingOnScreen } from 'hooks'
import { Animated } from 'components/feedback'


const Hero: FC = () => {
  const { isMobile } = useDevice()
  const ref = useRef()
  const isActive = UseThrottlingOnScreen(ref)

  return (
    <WidthContainer>
      <div className={cx(s.hero, 'flex flex-col items-center text-center mt-80')}>
        <Animated>
          <Text style="h2" tag="h2">
            Welcome to Babylon
          </Text>
        </Animated>
        <Animated>
          <div className={cx(s.headText, 'relative')}>
            <Text style="h1" tag="h1" html message={`HOME OF ${isMobile ? '<br />' : ''}NFT${isMobile ? '<br />' : ''} EDITIONS`} />
            <img className={cx(s.nft, isActive && s.nft_active)} src="/images/graphics/nft.png" alt="NFT" ref={ref} />
          </div>
        </Animated>
        <Text
          className={cx(isMobile ? 'mt-16' : 'mt-24', s.description)}
          style={isMobile ? 'p1' : 'p2'}
          tag="p"
          color="gray-50"
          message="We are a consortium of NFT artists and collectors guided by the principle that art is meant to be inclusive — from creation to ownership."
        />
        <div className={isMobile ? 'mt-32 flex flex-col w-full' : 'mt-40 flex'}>
          <Button
            title="collect editions"
            size={64}
            style="primary"
            to="/curated-gallery"
            onClick={() => event({ event: 'collect_editions', version: 'welcome' })}
            fullWidth={isMobile}
          />
          <Button
            className={isMobile ? 'mt-12' : 'ml-24'}
            title="list editions"
            size={64}
            style="stroked"
            to="/create"
            onClick={() => event({ event: 'list_editions', version: 'welcome' })}
            fullWidth={isMobile}
          />
        </div>
      </div>
    </WidthContainer>
  )
}

export default Hero
